<style scoped lang="less">

.lead-paragraph {
  font-size: 2em;
  font-weight: bold;
}

.Home {
  &_Block {
    padding-bottom: 1em;
  }
}

</style>
<template>
  <div class="Home">
    <b-row cols="1" cols-sm="1" cols-md="1" cols-lg="2" cols-xl="2">
      <b-col class="Home_Block">
        <div class="content">
          <p class="lead-paragraph">
            MCH2022 is over, see you at <a href="https://WHY2025.org" target="_blank"
            rel="noopener noreferrer">WHY2025!</a><br>
          </p>
          <p>
            MCH2022 was a nonprofit outdoor hacker camp taking place in Zeewolde, the Netherlands, July 22 to 26
            2022. The event wa organized <a href="https://wiki.mch2022.org/Volunteering_Guide" target="_blank"
                                            rel="noopener noreferrer">for and by volunteers</a> from and around all
            facets of the worldwide hacker community.
          </p>
          <p>
            Knowledge sharing, technological advancement, experimentation, connecting with your hacker peers
            and hacking are some of the core values of this event.
          </p>
          <p>
            MCH2022 was the successor of a string of similar events happening every four years since 1989.
            These are
            <a href="https://en.wikipedia.org/wiki/Galactic_Hacker_Party" target="_blank"
               rel="noopener noreferrer">GHP</a>,
            <a href="https://nl.wikipedia.org/wiki/Hacking_at_the_End_of_the_Universe" target="_blank"
               rel="noopener noreferrer">HEU</a>,
            <a href="https://nl.wikipedia.org/wiki/Hacking_in_Progress" target="_blank"
               rel="noopener noreferrer">HIP</a>,
            <a href="https://en.wikipedia.org/wiki/Hackers_at_Large" target="_blank"
               rel="noopener noreferrer">HAL</a>,
            <a href="https://en.wikipedia.org/wiki/What_the_Hack" target="_blank" rel="noopener noreferrer">WTH</a>,
            <a href="https://en.wikipedia.org/wiki/Hacking_at_Random" target="_blank"
               rel="noopener noreferrer">HAR</a>,
            <a href="https://en.wikipedia.org/wiki/Observe._Hack._Make." target="_blank"
               rel="noopener noreferrer">OHM</a> and
            <a href="https://en.wikipedia.org/wiki/Still_Hacking_Anyway" target="_blank"
               rel="noopener noreferrer">SHA</a>.
            <br><br>
            The next one will be: 
            <a href="https://WHY2025.org" target="_blank"
               rel="noopener noreferrer">WHY2025 (What Hackers Yearn (for))</a>.
            <br><br>
            Similar events are
            <span style="display: inline-block">🇬🇧<a href="https://www.emfcamp.org/" target="_blank"
                                                       rel="noopener noreferrer">Electromagnetic Field</a>, </span>
            <span style="display: inline-block">&nbsp; 🇩🇰<a href="https://www.bornhack.dk/" target="_blank"
                                                              rel="noopener noreferrer">Bornhack</a>, </span>
            <span style="display: inline-block">🇧🇪<a href="https://fri3d.be" target="_blank"
                                                       rel="noopener noreferrer">Fri3d Camp</a> and </span>
            <!-- unreachable: 🇮🇹 <a href="https://www.ihc.camp/2020/" target="_blank" rel="noopener noreferrer">IHC</a> in Italy -->
            <span style="display: inline-block">&nbsp; 🇩🇪<a
                href="https://en.wikipedia.org/wiki/Chaos_Communication_Camp" target="_blank"
                rel="noopener noreferrer">Chaos Communication Camp</a>.</span><br>
            <br>
            MCH was held 55km east of Amsterdam at <a href="https://osm.org/go/0E65_AaQ?layers=HN&amp;m="
                                                     target="_blank"
                                                     rel="noopener noreferrer">Scoutinglandgoed Zeewolde</a>.
          </p>

          <b-button size="lg" style="background-color: var(--tickets-background-color-solid); border:0px; color: var(--tickets-color);" href="https://media.ccc.de/c/MCH2022" rel="noopener nofollow" target="_blank">
            <img src="/images/pages/tickets/triangle.svg" height="30">
            Re-watch the talks
            <b-icon icon="arrow-up-right" shift-v="14" shift-h="2" font-scale="0.9"></b-icon>
          </b-button>


          <b-button size="lg" class="btn btn-success ml-2" href="https://wiki.mch2022.org" target="_blank"
                    rel="nofollow noopener">
            View more info on the wiki
            <b-icon icon="arrow-up-right" shift-v="14" shift-h="-2" font-scale="0.9"></b-icon>
          </b-button>

        </div>


        <div class="content mt-4">
          <h3>The party starts today!</h3>
          <p>So, you just bought your ticket... now what? There is a lot of stuff you can do to prepare and have the
            best MCH experience.</p>
          <ul>
            <li><a href="https://mch2022.org/design/" target="_blank" rel="nofollow">Create your custom kaleidoscope
              design</a></li>
            <li><a href="https://wiki.mch2022.org/Villages" target="_blank" rel="nofollow">Register your village</a>
            </li>
            <li><a href="https://moebelhaus.mch2022.org/" target="_blank" rel="nofollow">Order
              tents/tables/chairs/fridges for your village</a></li>
            <li><a href="https://map.mch2022.org/" target="_blank" rel="nofollow">Look at the terrain map</a></li>
            <li><a href="https://grafana.mch2022.org/" target="_blank" rel="nofollow">Geek out on statistics</a></li>
            <li><a href="https://wiki.mch2022.org/Promotion" target="_blank" rel="nofollow">Follow social media channels
              & download posters</a></li>
            <li>More to come...</li>
          </ul>
          <i><b>Protip</b>: villages can join earlier and leave later. Volunteers can join even earlier and leave even
            later. <br>To enjoy a (much) longer event consider
            <a href="https://wiki.mch2022.org/Villages" target="_blank" rel="nofollow">running a village</a> or <a
                href="https://wiki.mch2022.org/Volunteering_Guide" target="_blank" rel="nofollow">becoming a
              volunteer</a>.</i>

        </div>

      </b-col>
      <b-col class="Home_Block">
        <Blog_List class="mb-4" style="width: 100%" :title="'MCH2022 News'" :count="20"></Blog_List>

        <div class="content mt-4">
          <h3>Call for Participation</h3>
          <p>The CfP has been closed, *but* if you have a really awesome talk or last minute discovery that has huge
            impact on the world, the content team would like to know. Please send it in via the button below.</p>

          <b-button size="lg" class="btn btn-success ml-2" href="https://cfp.mch2022.org" target="_blank"
                    rel="nofollow noopener">
            View the Call For Participation
            <b-icon icon="arrow-up-right" shift-v="14" shift-h="-2" font-scale="0.9"></b-icon>
          </b-button>
        </div>

      </b-col>
    </b-row>


    <titlex :text="'What to expect'"/>

    <div class="content">
      <h2>Some things to expect at May Contain Hackers...</h2>

      <b-card-group deck class="mb-4">
        <b-card
            title="The programme"
            img-src="/images/gallery/thumbnails/SHA2017_STAGE_CC_BY_POLYFLOYD.jpeg"
            img-alt="...."
            img-top
            link="asd"
            tag="article"

        >
          <b-card-text>
            With well over 250 sessions, it's impossible to visit everything the programme has to offer. Luckily most
            talks are recorded and streamed, so you can watch all the talks at the same time.
          </b-card-text>

          <b-button href="https://program.mch2022.org" target="_blank" variant="primary">View
            the programme
          </b-button>
        </b-card>

        <b-card
            title="Finally normal people™"
            img-src="/images/gallery/thumbnails/SHA2017_POFFERTJES_CC_BY_SEBASTIAN_HUMBEK.jpeg"
            img-alt="...."
            img-top
            tag="article"
        >
          <b-card-text>
            <p>After two years of lockdown, this is an excellent moment to meet new friends and possibly see your old
              friends again.
              Between 3500 and 4200 hackers visit MCH. Meet like-minded individuals that have interesting
              stories and visions. Exchange ideas and
              have fun!</p>
          </b-card-text>

        </b-card>

        <b-card
            title="The badge"
            img-src="/images/gallery/thumbnails/mch2022_badge_CC_BY_BadgeTeam.jpg"
            img-alt="...."
            img-top
            tag="article"
        >
          <b-card-text>
            The first ~3500 visitors receive a programmable electronic event badge. Featuring a color display,
            ESP32, FPGA and a whole lot of sensors. Create and share your apps in the Hatchery.
          </b-card-text>

          <b-button href="/#/Blog/presenting-the-mch2022-badge" target="_blank" variant="primary">View the badge
            specs
          </b-button>
        </b-card>


      </b-card-group>
      <b-card-group deck class="mb-4">


        <b-card
            title="The Network"
            img-src="/images/gallery/thumbnails/SHA2017_DATENKLO_ROLLOUT_CC_BY_ELGER_JONKER.jpeg"
            img-alt="...."
            img-top
            tag="article"
            class="mb-2"
        >
          <b-card-text>
            <p>The Network Operation Center is planning to provide an ever faster uplink than the "regular old" 100
              gigabit in 2017.
              Oh, and there might be a surprise for all visitors.</p>
            <p>Use more bandwidth!</p>
          </b-card-text>

          <b-button
              href="https://tweakers.net/geek/126507/nederlands-hackercamp-sha2017-krijgt-100gbit-s-internetverbinding.html"
              target="_blank" variant="primary">Read the 2017 news article
          </b-button>

          <template #footer>
            <small class="text-muted">Image: NOC deploying <a href="https://de.wikipedia.org/wiki/Datenklo"
                                                              target="_blank" rel="nofollow">Datenklo's</a>.</small>
          </template>

        </b-card>

        <b-card
            title="Villages & Projects"
            img-src="/images/unique/SHA2017_AT_NIGHT_C_BY_REALORNOT42.jpg"
            img-alt="...."
            img-top
            tag="article"
        >
          <b-card-text>
            <p>Many groups of visitors assemble in villages. They bring their own unique perspective on the world an
              all kinds of awesome/'insane aka normal' projects.</p>

          </b-card-text>
          <b-button href="https://wiki.mch2022.org/Villages" target="_blank" variant="primary">List of villages
          </b-button>
        </b-card>


        <b-card
            title="The Terrain"
            img-src="/images/unique/terrain.jpg"
            img-alt="Picture of the south fields of the terrain."
            img-top
            tag="article"
        >
          <b-card-text>
            The Scouting Estate Zeewolde is a green and bright place, with trees, water bodies, a beach and a harbour.
            There is lots of space to set up your camp.
          </b-card-text>

          <b-button href="https://www.youtube.com/watch?v=QS7kkXWyAFo" target="_blank" variant="primary">Watch the
            MCH2022 Terrain trailer on YouTube.
          </b-button>

        </b-card>

      </b-card-group>
      <b-card-group deck class="mb-2">

        <b-card
            title="The Merch"
            img-src="/images/unique/merch.png"
            img-alt="...."
            img-top
            tag="article"
        >
          <b-card-text>
            <p>MCH tries to raise the bar by offering iconic and futuristic event clothing. We're currently sampling
              the designs to see how well it looks.</p>

          </b-card-text>
          <b-button href="https://tickets.mch2022.org" target="_blank" variant="primary">Order your merch</b-button>

          <template #footer>
            <small class="text-muted">Design by Nikolett. Final design might deviate from image.</small>
          </template>
        </b-card>

        <b-card
            title="Family Village"
            img-src="/images/unique/DO_NOT_PRESS_BY_JULIA_WOLF.jpg"
            img-alt="...."
            img-top
            tag="article"
        >
          <b-card-text>
            <p>The Family Village is here to support parents and their children making the most out of MCH. There is a
              programme and all kinds of facilities for kids.</p>
          </b-card-text>

          <b-button href="https://mch2022.org/#/Blog/mch2022-the-best-family-vacation" target="_blank" variant="primary">Read more about the best family vacation</b-button>

        </b-card>

        <b-card
            title="And so much more..."
            img-src="/images/gallery/thumbnails/SHA2017_CATERING_AND_TRACK_TENTS_CC_BY_SEBASTIAN_HUMBEK.jpeg"
            img-alt="...."
            img-top
            tag="article"
        >
          <b-card-text>
            <p>There is a harbour, a party area, bars, food vendors, supermarket, and there will be a bunch of
              announcements soon&trade;</p>
          </b-card-text>

          <b-button href="https://media.ccc.de/b/conferences/camp-NL/sha2017" target="_blank" variant="primary">View
            recordings from 2017
          </b-button>
        </b-card>
        <!--
        Todo:
        Angels / Volunteering
        Event is made for and by hackers. Angels...
        What non-profit does?
        Hint: you will not believe your ears
        Hint: sprites and pixels
        Hint: Don't stand there: operate!
        Hint: Where's the party?
        Hint: No contact!
        -->
      </b-card-group>

    </div>

    <!-- content / program blogpost
    <div class="content mt-4">
      <h2>Talks and workshops</h2>
      <p>You can expect hundreds of talks and workshops at May Contain Hackers.</p>
      <p>The content team slowly releases confirmed speakers in a series of blog posts. Highlighted speakers are mentioned
      below.</p>

      <b-card-group columns class="mb-4">

        <b-card
            title="Aiganysh"
            tag="article">
          <b-card-text>
            Open Source Intelligence collective Bellingcat will give a talk about OSINT and also organise a workshop in which you can get your hands dirty in that field.
          </b-card-text>
        </b-card>

        <b-card
            title="Mikko Hippönen"
            tag="article">
          <b-card-text>
            The Best Worst Thing: How the internet is becoming an infrastructure that is as critical as the electricity network
          </b-card-text>
        </b-card>

        <b-card
            title="Klaas van Gend, Pascal Ahout"
            tag="article">
          <b-card-text>
            Building a cheap laser harp for percussionists
          </b-card-text>
        </b-card>

        <b-card
            title="dwangoAC"
            tag="article">
          <b-card-text>
            TASBot N64 ACE: How to hack an N64 through the controller ports
          </b-card-text>
        </b-card>

        <b-card
            title="Ksenia Ermoshina"
            tag="article">
          <b-card-text>
            Communicating in the context of war: thread models and coping strategies of anti-war movement in Russia
          </b-card-text>
        </b-card>

        <b-card
            title="Karsten Nohl"
            tag="article">
          <b-card-text>
            OpenRAN - 5G hacking just got a lot more interesting
          </b-card-text>
        </b-card>

        <b-card
            title="This Alkemande, Daan Keuper"
            tag="article">
          <b-card-text>
            Hacking the pandemic's most popular software: Zoom
          </b-card-text>
        </b-card>

        <b-card
            title="Sonia"
            tag="article">
          <b-card-text>
            Hacktivism and Mental Health - CyberWell
          </b-card-text>
        </b-card>

      </b-card-group>

      <b-button href="https://program.mch2022.org" disabled target="_blank" variant="secondary">View the entire program! (disabled because the program is not published yet)...</b-button>
    </div>
    -->



    <div class="content" style="margin-top: 2em;">
      <a class="btn-block btn-success btn-lg" href="https://media.ccc.de/c/MCH2022" target="_blank"
         rel="nofollow noopener">
        Re-watch the talks
        <b-icon icon="arrow-up-right" shift-v="8" shift-h="-2" font-scale="0.9"></b-icon>
      </a>
    </div>
  </div>
</template>
<script>
import {mapGetters} from "vuex";

import Blog_List from "@/components/pages/Blog_List";
//import galleryx from "@/components/Gallery";
// import Tba from "../tba";
import Titlex from '@/components/title';

export default {
  components: {Blog_List,Titlex}, // galleryx,
  data: () => ({}),
  props: {},
  methods: {
    getDate(date) {
      return date.getFullYear().toString() + '-' +
          (date.getMonth() + 1).toString().padStart(2, '0') + '-' +
          date.getDate().toString().padStart(2, '0');
    },
  },
  computed: {
    ...mapGetters({
      posts: 'blog/reversedPosts',
      storeLoaded: 'blog/loaded',
      storeError: 'blog/error',
    }),
  },
}
</script>
