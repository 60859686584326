<style scoped>

.img-thumbnail {
  border-bottom: 0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  transition: transform .2s; /* Animation */
}

.figure-caption {
  margin-top: -3px;
  font-size: 0.8em;
  font-style: normal;
  background-color: rgba(255, 255, 255, 1);
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  padding: 4px;
  color: #331a38ff;
}

.img-thumbnail:hover {
  transform: scale(1.02);
}

</style>
<template>
    <b-row>
      <b-col>
        <div class="content" style="margin-top: 20px;">
          <h2>Memories of SHA2017</h2>
          <p>SHA2017 was the previous edition of this event, held at the same location. This gallery shows a few of the many, many great pictures taken. More pictures are available on <a href="https://wiki.sha2017.org/w/Memories" target="_blank" rel="nofollow noopener">this SHA2017 memories page</a> and on popular image sharing sites such as on <a href="https://www.flickr.com/search/?text=SHA2017" target="_blank" rel="nofollow noopener">this flickr page</a>.</p>
          <b-row>
            <b-col>
              <figure class="figure">
                <b-img v-img:gallery thumbnail fluid
                       src="/images/gallery/thumbnails/SHA2017_TERRAIN_CC_BY_SEBASTIAN_HUMBEK.jpeg"
                       alt="Aerial picture of the MCH2022 terrain, taken during the previous camp: SHA2017. SHA was visited by >3500 hackers. (Photo by Sebastian Humbek)"></b-img>
                <figcaption class="figure-caption">Aerial picture of the MCH2022 terrain, taken during the previous camp: SHA2017. SHA was visited by >3500 hackers. </figcaption>
              </figure>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <figure class="figure">
                <b-img style="min-width: 150px" v-img:gallery thumbnail fluid
                       src="/images/gallery/thumbnails/SHA2017_STAGE_CC_BY_POLYFLOYD.jpeg"
                       alt="Well over 300 talks and workshops. This was the LED display on the main stage just before the opening talk. (Photo by Polyfloyd)"></b-img>
                <figcaption class="figure-caption">Well over 300 talks and workshops. This was the LED display on the main stage just before the opening talk.</figcaption>
              </figure>
            </b-col>
            <b-col>
              <figure class="figure">
                <b-img style="min-width: 150px" v-img:gallery thumbnail fluid
                       src="/images/gallery/thumbnails/SHA2017_MAIN_ROAD_CC_BY_POLYFLOYD.jpeg"
                       alt="The main road viewed from the dike. Showing all the large facility tents, such as the stages on the right and angel area on the left. (Photo by Polyfloyd)"></b-img>
                <figcaption class="figure-caption">The main road viewed from the dike. Showing all the large facility tents, such as the stages on the right and angel area on the left.</figcaption>
              </figure>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <figure class="figure">
                <b-img style="min-width: 150px" v-img:gallery thumbnail fluid
                       src="/images/gallery/thumbnails/SHA2017_POFFERTJES_CC_BY_SEBASTIAN_HUMBEK.jpeg"
                       alt="At night the camp shows its vibrant colors with beams onto the clouds. The light from the lighthouse swoops over the terrain, next to a sea of blue lasers. The SHA2017 LED sign is blinking while someone next to it is handing out <a href='https://en.wikipedia.org/wiki/Poffertjes' target='_blank' rel='nofollow noopener'>poffertjes</a>. (Photo by Sebastian Humbek)"></b-img>
                <figcaption class="figure-caption">At night the camp shows its vibrant colors with beams onto the clouds. The light from the lighthouse swoops over the terrain, next to a sea of blue lasers. The SHA2017 LED sign is blinking while someone next to it is handing out <a href="https://en.wikipedia.org/wiki/Poffertjes" target="_blank" rel="nofollow noopener">poffertjes</a>.</figcaption>
              </figure>
            </b-col>
            <b-col>
              <figure class="figure">
                <b-img style="min-width: 150px" v-img:gallery thumbnail fluid
                       src="/images/gallery/thumbnails/SHA2017_ENTRANCE_GATE_BY_NIGHT_CC_BY_POLYFLOYD.jpeg"
                       alt="Entrance gate to the event terrain. (Photo by Polyfloyd)"></b-img>
                <figcaption class="figure-caption">Entrance gate to the event terrain at night.</figcaption>
              </figure>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <figure class="figure">
                <b-img style="min-width: 150px" v-img:gallery thumbnail fluid
                       src="/images/gallery/thumbnails/SHA2017_CAMPING_FIELDS_CC_BY_SEBASTIAN_HUMBEK.jpeg"
                       alt="Tents are assembled in villages. These are the southern fields, which stretch the longest. The trees as well as many villages are shining bright. (Photo by Sebastian Humbek)"></b-img>
                <figcaption class="figure-caption">Tents are assembled in villages. These are the southern fields, which stretch the longest. The trees as well as many villages are shining bright.</figcaption>
              </figure>
            </b-col>
            <b-col>
              <figure class="figure">
                <b-img style="min-width: 150px" v-img:gallery thumbnail fluid
                       src="/images/gallery/thumbnails/SHA2017_FLAMETHROWER_CC_BY_SEBASTIAN_HUMBEK.jpeg"
                       alt="Flamethrowers included, because batteries don't cut it. Although... batteries can become flamethrowers too! (Photo by Sebastian Humbek)"></b-img>
                <figcaption class="figure-caption">Flamethrowers included, because batteries don't cut it. Although... batteries can become flamethrowers too! </figcaption>
              </figure>
            </b-col>
          </b-row>
          <b-row>
            <b-col>

              <figure class="figure">
                <b-img style="min-width: 150px" v-img:gallery thumbnail fluid
                       src="/images/gallery/thumbnails/SHA2017_BADGE_OVERVIEW_CC_BY_BADGE.TEAM.jpeg"
                       alt="SHA2017 visitors also got their own badge. Team:Badge is working on a new spectacular and boundary breaking badge for MCH2022. (Photo by Badge.Team)"></b-img>
                <figcaption class="figure-caption">SHA2017 visitors also got their own badge. Team:Badge is working on a new spectacular and boundary breaking badge for MCH2022.</figcaption>
              </figure>
            </b-col>
            <b-col>
              <figure class="figure">
                <b-img style="min-width: 150px" v-img:gallery thumbnail fluid
                       src="/images/gallery/thumbnails/SHA2017_SILENT_LOUNGE_CC_BY_BEN_VAN_DEN_BRAND.jpeg"
                       alt="The silent lounge provided a calm atmosphere to relax. (Photo by Ben van den Brand)"></b-img>
                <figcaption class="figure-caption">The silent lounge provided a calm atmosphere to relax.</figcaption>
              </figure>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <figure class="figure">
                <b-img style="min-width: 150px" v-img:gallery thumbnail fluid
                       src="/images/gallery/thumbnails/SHA2017_SAFE_HARBOUR_CC_BY_POLYFLOYD.jpeg"
                       alt="The safe harbour allows you to come by boat. Visitors can also ferry to the other side of the river. (Photo by Polyfloyd)"></b-img>
                <figcaption class="figure-caption">The safe harbour allows you to come by boat. Visitors can also ferry to the other side of the river.</figcaption>
              </figure>
            </b-col>
            <b-col>
              <figure class="figure">
                <b-img style="min-width: 150px" v-img:gallery thumbnail fluid :height="200"
                       src="/images/gallery/thumbnails/SHA2017_TERRAIN_2_CC_BY_SEBASTIAN_HUMBEK.jpeg"
                       alt="Another overview of (most of) the campsite. (Photo by Sebastian Humbek)"></b-img>
                <figcaption class="figure-caption">Another overview of (most of) the campsite.</figcaption>
              </figure>
            </b-col>
          </b-row>
        </div>
      </b-col>
    </b-row>
</template>
<script>
export default {}
</script>
