import { render, staticRenderFns } from "./Sponsors.vue?vue&type=template&id=1ad5eace&scoped=true&"
import script from "./Sponsors.vue?vue&type=script&lang=js&"
export * from "./Sponsors.vue?vue&type=script&lang=js&"
import style0 from "./Sponsors.vue?vue&type=style&index=0&id=1ad5eace&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1ad5eace",
  null
  
)

export default component.exports