<style scoped lang="less">

.lead-paragraph {
  font-size: 1.1em;
  font-weight: bold;
}

.Home {
  &_Block {
    padding-bottom: 1em;
  }
}

</style>
<template >
    <div class="content" style="margin-top: 2em;">
        <b-container class="content_example">
            <b-row>
            We are committed to making MCH2022 an inclusive and welcoming event for everyone. To accomplish this, we have one very important guiding principle:

            <h4><b>Be excellent to each other!</b></h4>
            <p>
            To help us achieve this excellency, we've set ground rules that define what kind of behaviour we consider to be unacceptable during MCH2022. Setting 
            these rules makes it crystal clear what we expect from the people attending our wonderful event. They also help us to facilitate an event where 
            everyone can feel comfortable, included and can fully engage in everything we have to offer.
            </p>
            <p>Everyone who visits MCH2022 and/or helps in organising the event is required to abide by this code of conduct, both at the event and on any 
                online channels. We will not tolerate harassment of participants or discriminatory behaviour of any form. More specific:
            </p>
            <ul>
                <li>Do not engage in homophobic, racist, transphobic, ableist, sexist, fatphobic or otherwise prejudiced behaviour.</li>
                <li>Do not harass people. Stalking, unconsented physical contact, or unwanted sexual attention is harassment. Dressing or acting in a certain way is not consent.</li>
                <li>MCH2022 is a shared space, but every participant's personal space is their own. If you are asked to leave someone alone, or to leave their village, you must respect this.</li>
                <li>Some attendees may not want to be filmed or photographed. Always ask for permission first and respect their wishes.</li>
                <li>Aggression and elitism are not welcome — nobody should be afraid to ask questions.</li>
                <li>If you break these rules, we may expel you from the event without a refund, and you may not be welcome at future Dutch events.</li>
            </ul>
            <h2>Contact</h2>
            <p>If you are being harassed, feel unsafe, want to talk, witness a breach of this code of conduct, 
            or for any other reason want to contact someone form the Cohesion team, you can get in touch with 
            us in the following ways: Drop us an email at <a href="mailto:cohesion@mch2022.org">cohesion@mch2022.org</a>. 
            Or call/SMS at +31 6 86 88 41 78 (during the event only). If you prefer to specifically speak to a 
            person of a certain gender, please specify this when you contact us.
            </p>

            <h2>Take care of your surroundings</h2>
            <p>Finally, MCH2022 takes place in an area with beautiful nature all around. We expect everyone to treat the venue with the utmost care and respect. We will not tolerate any kind of vandalism against nature, this includes littering. We are guests in nature, and everyone is expected to act as such.
            </p>
            <p>If you have any comments or queries, get in touch with our Cohesion team at <a href="mailto:cohesion@mch2022.org">cohesion@mch2022.org</a>.</p>


            <i>This Code of Conduct is heavily based on the Code of Conduct from our friends at EMF and Bornhack.</i>

            </b-row>
        </b-container>
    </div>
</template>
<script>
    export default {
        data: () => ({

        }),
        props: {},
        methods: {},
}
</script>
