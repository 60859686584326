<template>
  <div class="h2container">
    <h2>{{ text }}</h2>
  </div>
</template>

<script>
export default {
  name: "title.vue",
  props: {
    text: {type: String},
  },
  computed: {
    background_color_ff: function(){
      if (this.doesNotSupportBackdropFilter()) {
        return this.background_color.substr(0, 7) + 'ff';
      }
      return this.background_color
    }
  }
}
</script>

<style scoped lang="scss">
  @import "~bootstrap/scss/functions";
  @import "~bootstrap/scss/variables";
  @import "~bootstrap/scss/mixins/breakpoints";

  .h2container {
    border: 13px solid #eeeeeeaa;
    border-top: 10px solid #eeeeeeaa;
    border-bottom: 10px solid #eeeeeeaa;
    border-radius: 20px;
    padding: 0.5em;
    text-align: center;

    margin: 2.35em;
    margin-top: 2em;
    margin-bottom: 2em;

    transform: skew(-30deg);

    @include media-breakpoint-up(md) {
      -webkit-backdrop-filter: blur(30px);
      backdrop-filter: blur(30px);
    }

    background-color: var(--background-color);
    color: var(--color);
  }

  h2 {
    font-size: 6em;
    transform: skew(30deg);
    font-family: 'sairacondensed', sans-serif;
  }


@include media-breakpoint-only(xs) {
  .h2container {
    margin: 1em 0.8em;
  }
  h2 {
    font-size: 1.75em;
  }
}
@include media-breakpoint-only(sm) {
  h2 {
    font-size: 2.25em;
  }
}
@include media-breakpoint-only(md) {
  h2 {
    font-size: 3.5em;
  }
}
@include media-breakpoint-only(lg) {
  h2 {
    font-size: 5em;
  }
}

</style>