<template>
  <div id="app" :class="`${currentPageName}`">
    <b-container>

      <Menu></Menu>

      <titlex :text="currentPageName in pages ? pages[currentPageName].title : 'Somewhere'"/>

      <keep-alive>
        <router-view></router-view>
      </keep-alive>

      <BottomMenu></BottomMenu>


    </b-container>

  </div>
</template>
<script>
import BottomMenu from "./components/BottomMenu"
import Menu from "./components/Menu"
import Titlex from './components/title'

const TITLE = "May Contain Hackers 2022";

export default {
  name: 'App',
  data: function () {
    return {
      currentPageName: "home",

      pages: {
        home: {
          title: "May Contain Hackers",
          color: "#fec859ff",
          background_color: "#491d88aa"
        },
        blog: {
          title: "Weblog", // " Weblog"
          page_title: "Weblog",
          color: "#fa448cff",
          // the transparency should be of for firefox, as that doesn't do blur by default
          background_color: "#331a38aa"
        },
        blog_entry: {
          title: "Weblog",
          page_title: () => this.$store.getters['blogTitle'],
          color: "#fa448cff",
          background_color: "#331a38aa"
        },
        sponsors: {
          title: "Sponsors", // "sponsors      ", new: "       Sponsors"
          color: "#491d88ff",
          background_color: "#43b5a055"
        },
        oauth: {
          title: "Signing in...",
          color: "#fec859ff",
          background_color: "#491d88aa"
        },
        press: {
          title: "Press resources",
          color: "#491d88ff",
          background_color: "#43b5a055"
        },
        CoC: {
          title: "Code of Conduct",
          color: "#491d88ff",
          background_color: "#43b5a055"
        },
        memories: {
          title: "Memories of SHA2017",
          color: "#fec859ff",
          background_color: "#491d88aa"
        },
      }
    }
  },
  computed: {
    page_title: function() {
      let val = this.pages[this.currentPageName].page_title;
      if (val instanceof Function) {
        return val();
      }
      return val;
    },
  },
  watch: {
    $route: function () {
      this.setCurrentPageName();
    },
    page_title: function() {
      if (this.page_title) {
        document.title = `${this.page_title} | ${TITLE}`;
      } else {
        document.title = TITLE;
      }
    }
  },
  mounted: function () {
    this.setCurrentPageName();
  },
  methods: {
    setCurrentPageName: function () {
      if (this.$router.currentRoute.meta.design === undefined) {
        this.currentPageName = "home";
      }
      this.currentPageName = this.$router.currentRoute.meta.design;
    },
  },

  components: {
    BottomMenu,
    Menu,
    Titlex
  },
}
</script>
<style lang="less">
@import (reference) "src/_variables";

@font-face {
  font-family: 'sairacondensed';
  src: url('/fonts/SairaCondensed-Medium.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'sairaregular';
  src: url('/fonts/Saira-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

// TODO: This should be handled by the components itself.
/* make sure content > wallpaper */
.content {
  border-radius: 10px;
  padding: 1em;

  // Default, for firefox and opera support
  // https://caniuse.com/?search=backdrop-filter
  background-color: rgba(224, 224, 224, 0.98);

  @media @md-and-up {
    @supports ((-webkit-backdrop-filter: blur(2em)) or (backdrop-filter: blur(2em))) {
      background-color: rgba(255, 255, 255, 0.6);
      -webkit-backdrop-filter: blur(30px);
      backdrop-filter: blur(30px);
    }
  }
}
  a.btn-lg, button.btn {
    background-color: var(--background-color-solid);
  }
</style>
<style lang="less" scoped>
  @import (reference) "src/_variables";

#app {
  font-family: 'sairaregular', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  color: #2c3e50;
  font-size: 1.2em;
  min-height: 100vh;
  width: 100%;
  padding-top: 2em;
  padding-bottom: 2em;
  background-color: #ccc; // Greetings! // #b7b7b7
  background-repeat: repeat;
  background-position: center top;
  background-attachment: fixed;

  background-image: var(--wallpaper);

  /* Page styling */

  /* Page: default / May Contain Hackers */
  --home-color: #fec859ff;
  --home-background-color: var(--home-background-color-solid);
  --home-background-color-solid: #491d88ff;
  @media @md-and-up {
    @supports ((-webkit-backdrop-filter: blur(2em)) or (backdrop-filter: blur(2em))) {
      --home-background-color: #491d88aa;
    }
  }
  --home-wallpaper: url('/images/pages/home/wallpaper.png');

  /* Page: blog / " Weblog" */
  --blog-color: #fa448cff;
  --blog-background-color: var(--blog-background-color-solid);
  --blog-background-color-solid: #331a38ff;
  @media @md-and-up {
    @supports ((-webkit-backdrop-filter: blur(2em)) or (backdrop-filter: blur(2em))) {
      --blog-background-color: #331a38aa;
    }
  }
  --blog-wallpaper: url('/images/pages/blog/wallpaper.png');

  /* Page: Sponsors / "       Sponsors" */
  --sponsors-color: #491d88ff;
  --sponsors-background-color: var(--sponsors-background-color-solid);
  --sponsors-background-color-solid: #43b5a0ff;
  @media @md-and-up {
    @supports ((-webkit-backdrop-filter: blur(2em)) or (backdrop-filter: blur(2em))) {
      --sponsors-background-color: #43b5a055;
    }
  }
  --sponsors-wallpaper: url('/images/pages/sponsors/wallpaper.png');

  /* Page: Tickets */
  --tickets-color: #491d88ff;
  --tickets-background-color-solid: #fa448cff;

  /* Glue: Assign active color scheme */
  & { // &.home // This is the default color sheme
    --color: var(--home-color);
    --background-color: var(--home-background-color);
    --background-color-solid: var(--home-background-color-solid);
    --wallpaper: var(--home-wallpaper);
  }
  &.blog, &.blog_entry {
    --color: var(--blog-color);
    --background-color: var(--blog-background-color);
    --background-color-solid: var(--blog-background-color-solid);
    --wallpaper: var(--blog-wallpaper);
  }
  &.sponsors {
    --color: var(--sponsors-color);
    --background-color: var(--sponsors-background-color);
    --background-color-solid: var(--sponsors-background-color-solid);
    --wallpaper: var(--sponsors-wallpaper);
  }
}
</style>
