<template>
  <a :href="url" class="align-middle" rel="nofollow noopener" target="_blank">
    <figure class="figure mt-3 mb-5 w-100" >
      <b-img fluid thumbnail rounded :src="'/images/pages/sponsors/' + filename" :alt="alt" class="p-4" />
      <figcaption class="figure-caption text-center mt-2">{{caption}}</figcaption>
    </figure>
  </a>
</template>

<script>
export default {
  name: "CompanyLogo",
  props: {
    url: {type: String, required: true},
    filename: {type: String, required: true},
    alt: {type: String, required: true},
    caption: {type: String, required: true}
  }
}
</script>
<style scoped>
.img-thumbnail {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.figure {
  height: 300px;
}
.material-sponsor .figure {
  height: 150px;
}
</style>
