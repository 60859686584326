<template >
    <div></div>
</template>
<script>
    export default {
        mounted () {
            this.$oidc.signinSilentCallback().then();
        }
}
</script>
