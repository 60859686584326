<style scoped lang="less">
  @import (reference) "../_variables";
  .navbar {
    border-radius: 10px;

    background-color: rgba(224, 224, 224, 0.98);

    @media @md-and-up {
      @supports ((-webkit-backdrop-filter: blur(2em)) or (backdrop-filter: blur(2em))) {
        background-color: rgba(255, 255, 255, 0.4);
        -webkit-backdrop-filter: blur(30px);
        backdrop-filter: blur(30px);
      }
    }
  }
</style>

<template>

    <!-- Docs: https://bootstrap-vue.org/docs/components/navbar -->
    <b-navbar toggleable="lg" type="light" sticky>
      <b-navbar-brand>
        <b-button style="background-color: var(--home-background-color-solid); border:0px;">
        <router-link to="/" >
        <img src="/images/pages/home/triangle.svg" height="30"></router-link> <router-link to="/" style="font-weight: bold; color: var(--home-color);">
        May Contain Hackers</router-link>

        </b-button>
      </b-navbar-brand>

      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav>

          <b-nav-item>
            <b-button style="background-color: var(--blog-background-color-solid); border:0px;">
            <router-link to="/Blog">
              <img src="/images/pages/blog/triangle.svg" height="30"></router-link>
              <router-link to="/Blog" style="color: var(--blog-color);">News</router-link>
            </b-button>
          </b-nav-item>

          <b-nav-item right>
            <b-button style="background-color: var(--sponsors-background-color-solid); border:0px;">
            <router-link to="/Sponsors"><img src="/images/pages/sponsors/triangle.svg" height="30"></router-link>
              <router-link to="/Sponsors" style="color: var(--sponsors-color);">Sponsors</router-link>
            </b-button>
          </b-nav-item>

        </b-navbar-nav>

        <!-- Right aligned nav items -->
        <b-navbar-nav class="ml-auto">


          <b-nav-item right href="https://tickets.mch2022.org" target="_blank" rel="nofollow noopener">
            <b-button style="background-color: var(--tickets-background-color-solid); border:0px;" href="https://tickets.mch2022.org" rel="nofollow noopener" target="_blank">
              <span style="color: var(--tickets-color);">
                Buy Tickets <b-icon icon="arrow-up-right" shift-v="40" shift-h="-5" font-scale="0.4" />
              </span>
            </b-button>
          </b-nav-item>

          <!-- <b-nav-item>
            <b-button style="background-color: var(--home-background-color-solid); border:0px;" to="/Memories">
              <span style="color: var(--home-color);">
                Memories
              </span>
            </b-button>
          </b-nav-item>
          -->

          <b-nav-item right href="https://program.mch2022.org/" target="_blank" rel="nofollow">

            <b-button style="background-color: var(--home-color); border:0px;" href="https://program.mch2022.org/" target="_blank" rel="nofollow noopener">
              <span style="color: var(--tickets-color);">
                Program <b-icon icon="arrow-up-right" shift-v="40" shift-h="-5" font-scale="0.4" />
              </span>
            </b-button>

            <!-- CfP <b-icon icon="arrow-up-right" shift-v="14" shift-h="-2" font-scale="0.9" /> -->
          </b-nav-item>
          <b-nav-item right href="https://wiki.mch2022.org" target="_blank" rel="nofollow" >

              <b-button style="background-color: var(--sponsors-background-color); border:0px;" href="https://wiki.mch2022.org" target="_blank" rel="nofollow noopener">
                <span style="color: var(--tickets-color);">
                  Wiki <b-icon icon="arrow-up-right" shift-v="40" shift-h="-5" font-scale="0.4" />
                </span>
              </b-button>

          </b-nav-item>

        </b-navbar-nav>
      </b-collapse>
    </b-navbar>

</template>

<script>
export default {}
</script>
