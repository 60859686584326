<style lang="less">
@import (reference) "src/_variables";

/* This is not scoped, so we use the filename as a class selector prefix */
.Blog_Entry {
  &__Title {

  }

  &__Content {
    max-width: 100%;

    * {
      scroll-margin-top: 75px;
    }

    img {
      width: 100%;
      height: initial;
      object-fit: contain;
    }

    .wp {
      &-block {
        &-image {
          img {
            border-radius: 10px;
          }

          @media @sm-and-below {
            img {
              max-width: 100%;
              height: auto;
            }
          }

          figcaption {
            font-size: 16px;
            font-weight: bold;
            text-align: center;
          }
        }

        &-table {
          background-color: @caption-background;
          border: 1px solid @border-color;

          tr {
            &:not(:last-child) {
              border-bottom: 1px solid @border-color;
            }

            &:first-child td {
              border-bottom: 2px solid @border-color;
            }
          }
        }

        &-gallery {
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          padding: 0;
        }

        &-columns {
          @media @md-and-up {
            display: flex;
          }
        }
        &-column {
          @media @md-and-up {
            padding: 0 10px;
          }
          padding: 0 5px;
        }
        &-embed {
          @media @sm-and-below {
            iframe {
              max-width: 100%;
            }
          }
        }

        &-preformatted {
          white-space: pre-wrap;
        }
      }
    }

    each(range(8), {
      .columns-@{value} > * {
        flex: 0 0 100px;
        flex-basis: 100% / @value;
        @media @md-and-up {
          padding: 0 10px;
        }
        padding: 0 5px;
        inline-size: 100% / @value;
      }
    });

    figcaption {
      overflow-wrap: break-word;
      hyphens: auto;
    }

    @media @md-and-up {


      .alignright {
        float: right;
      }

      .size {
        &-large {
          padding: 0 10px;
        }
      }
    }
  }
}
</style>

<template>
  <div>
    <b-row v-if="!storeLoaded && !storeError">
      Loading...
    </b-row>
    <b-row v-else-if="storeError">
      {{ storeError }}<br/>
      <b-button style="border:0px;" v-on:click="reloadPosts">
        Reload posts
      </b-button>
    </b-row>
    <b-row v-else-if="!entry">
      Unable to find this post. Are you sure this link is correct?
    </b-row>
    <b-row v-else>
      <b-col>
        <div class="Blog_Entry content">
          <h1 v-html="entry.title.rendered" class="Blog_Entry__Title"></h1>
          <p class="w-100 text-right">{{ entry.status === 'publish' ? 'Published' : entry.status.toUpperCase() }} on:
            <b-icon icon="clock"></b-icon>
            <span v-html="getDate(entry.date_gmt)"></span><span v-if="authors.length">, by: {{ authors.join(', ') }}</span></p>
          <div v-html="entry.content.rendered" class="Blog_Entry__Content"></div>
        </div>

        <Blog_List style="margin-top: 2em; width: 100%"></Blog_List>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import Blog_List from "@/components/pages/Blog_List";

export default {
  components: {Blog_List},
  data: () => ({}),
  computed: {
    ...mapGetters({
      posts: 'blog/posts',
      storeLoaded: 'blog/loaded',
      storeError: 'blog/error',
    }),
    index() {
        let id = parseInt(this.$route.params.id);
        if (!isNaN(id) && id > 0) {
            return this.posts.findIndex(post => post.id === id);
        } else {
            return this.posts.findIndex(post => post.slug === this.$route.params.name);
        }
    },
    postExists() {
      return this.index >= 0;
    },
    entry() {
      return this.posts[this.index];
    },
    authors() {
      return this.entry._embedded['wp:term'][0].map(a => a.name).filter(a => a.toLowerCase().startsWith('team:'));
    },
  },
  props: {},
  methods: {
    getDate(date) {
      return date.getFullYear().toString() + '-' +
          (date.getMonth() + 1).toString().padStart(2, '0') + '-' +
          date.getDate().toString().padStart(2, '0');
    },
    reloadPosts() {
      this.$store.dispatch('blog/refresh').then();
    },
  },
  watch: {
    entry() {
      this.$store.commit('setBlogTitle', this.entry.title.rendered);
      let section = this.$router.currentRoute.hash.replace("#", "");

      if (section) {
        console.log(`Jumping to section "${section}"`);
        window.document.getElementById(section).scrollIntoView()
      }
    }
  }
}
</script>
