<template>
  <div>
    <div style="width: 100%; text-align: right"><span v-on:click="v => this.$oidc.signinRedirect()" :title="signinStatus ? 'Signed-in' : 'Signed-out'" style="font-size: large;">{{ signinStatus ? '🔓' : '🔒'}}</span> <a href="/feed.rss"><img src="/images/pages/blog/rss.svg" width="28px"></a></div>
    <Blog_Previews></Blog_Previews>
    <Blog_List style="margin-top: 2em; width: 100%"></Blog_List>
  </div>
</template>
<script>
import Blog_List from "@/components/pages/Blog_List";
import Blog_Previews from '@/components/pages/Blog_Previews'

export default {
  components: {
    Blog_Previews, Blog_List
  },
  data: function () {
    return {}
  },
  computed: {
      signinStatus: function () {
          return !!this.$store.state.user;
      },
  }
}
</script>
