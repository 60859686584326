<template >
    <div></div>
</template>
<script>
    export default {
        mounted () {
            this.$oidc.signinRedirectCallback()
                .catch((err) => {
                    console.error(err)
                })
                .finally(() => {
                    // Nuke state/session_state url parameters
                    window.location.replace('/');
                })
            ;
        }
}
</script>
