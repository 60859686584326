<template>
  <div>

    <div class="content" style="margin-top: 2em;">
      <b-row>
        <b-col>

          <!--
            Todo:
              - Add a phone number when it's available. (the press team).
              - Fix high res photo link.
          -->

          <b-card no-body>
            <b-tabs card content-class="mt-2">
              <b-tab title="Joining the event as press">
                <h2>Joining the event as press</h2>

                <p>During your visit, a volunteer can guide you. These guides are knowledgeable about the event, cool
                  sites,
                  the programme and the hacker scene. In our experience, scrolling past just the highlights might take
                  you
                  well over two hours depending on your interests. You'll receive more practical information during the
                  week
                  of the event. Please specify other needs in your request.</p>

                <p>To request press access, please contact the press team at <strong><a
                    href="mailto:press@mch2022.org">press@mch2022.org</a></strong>
                  <strong><a href="tel:+???????"></a>. </strong>with the following information</p>

                <ul>
                  <li>Which media you work for / audience / reach (including URL's)</li>
                  <li>The kind of media planned: video, audio, pictures, text</li>
                  <li>How big the media team will be</li>
                  <li>Planned arrival date <strong>+ time</strong>, and planned duration of stay</li>
                  <li>If there is a need for parking (and how many vehicles)</li>
                  <li>Any other pratical matters</li>
                </ul>
                <h2 id="Rules" name="Rules">Practicalities &amp; rules</h2>

                <p>We appreciate the press and obviously care a lot about free speech. However, we - and our visitors! -
                  like
                  our privacy a lot. On the other hand, there are loads of people who want to show you their work and
                  views.</p>

                <ul>
                  <li>Pre-register so we can give you access to the field in a swift manner. While on the field, you can
                    announce yourself at the entrance and furthermore at the info desk.
                  </li>
                  <li>A press badge &amp; lanyard will help visitors (including volunteers) identify you as press. Wear
                    your
                    press badge visibly and openly so visitors know you’re with the press.
                  </li>
                  <li>Questions? Want a tour of the field? Want to interview someone from the organisation, or one of
                    the
                    speakers?<br>
                    Please walk by the info desk, contact press@mch2022.org<strong><a href="tel:+???????"></a></strong> . We have some volunteers
                    willing
                    to guide you for a tour.
                  </li>
                  <li>Respect privacy: <b>do not</b> make recognisable photo/video of our visitors, even when in the
                    background. Some
                    visitors have good reasons, such as personal safety. You can always ask for explicit permission. All
                    speakers can be pictured during their talk - unless otherwise stated at the start of the talk. Do
                    not
                    record the faces of visitors without their explicit permission. We have visitors that have good
                    reasons
                    for anonymity. With people in the background, you could blur faces or position yourself so people
                    are only
                    filmed neck-down. Or, position yourself so that people would have to willingly pass your camera. Our
                    press
                    guides will help you with that.
                  </li>
                  <li>Do not photograph or interview on site without talking with Press team first.</li>
                  <li>‘Villages’ (the sub-camps on the field) and workshops may deny you (or anyone else) entry.</li>
                  <li>We have a diverse group and value free speech. Statements by visitors, volunteers and speakers are
                    their
                    own, and often are not the consensus voice. The Press team is able to tell you which person speaks
                    on
                    behalf of the organisation.
                  </li>
                </ul>
              </b-tab>
              <b-tab title="General information">

                <h2>General information</h2>

                <p>May Contain Hackers, or MCH2022, is a campsite organized for and by hackers. The camp takes place
                  once
                  every four years and attracts thousands of visitors from around the world. This year it serves as the
                  biggest outdoor hacker event worldwide.</p>

                <p>At the campsite hackers share their projects, views and can choose to visit over 300 talks about
                  technology
                  and society. The camp has various facilities aimed at hackers:</p>

                <ul>
                  <li>field-wide WiFi and network cables to every tent with a 100 gigabit internet connection - the
                    fastest
                    ever on a campsite.
                  </li>
                  <li>a DECT system so you can use your cordless home phone to dial anyone on the field</li>
                  <li>a lot of power generators to provide every machine with enough power - including the electric cars
                    that
                    will be hacked. An experimental 42V smart grid will show how sustainable energy can be used at
                    festivals
                    and camps.
                  </li>
                  <li>a harbour for those who come by ship,</li>
                  <li>a game arcade with pinball machines and more - maintained by the hackers themselves</li>
                  <li>workshop facilties ranging from 3D-printers to SMD solderering tiny components or welding pieces
                    of
                    metal, to kitchens for 'food hacking'.
                  </li>
                  <li>an electronic badge - hand-made badge for every visitor that can show custom information on the
                    e-ink
                    display, has a wifi connection and LEDs.
                  </li>
                </ul>
                <p>MCH is organized by a group of hackers who believe in world improvement through freedom of technology
                  and
                  information. As such MCH is characterized as five days of hands on tinkering and ideological debates.
                  The
                  camp gives the hacker community a major energy boost, which helps in this mission.</p>

                <p>Being at MCH means being immersed in an exciting world filled with expertise, unique installations,
                  discussions, works of art, blinking lights and various other impressive tech and horizon expanding
                  world
                  views. From lasers, to leds, to philosophy, to soldering: there is nothing like it.</p>

                <p>Similar to the creative freedom spirit from festivals like Burning Man, a lot of visitors bring their
                  own
                  projects to show others and to collaborate on and create their own villages within the camp. Far more
                  than
                  only hacking security, we create things. Some villages are based on the many countries and cities
                  people
                  come from, others based on an interest like food, wearables, radio, hacking Tesla cars, drones, or
                  being a
                  parent and a hacker.</p>

                <p>MCH welcomes everyone with an interest in technology and/or world improvement to join. The event is
                  held
                  from the 22nd to 26th of July 2022 in Zeewolde, The Netherlands. Tickets are available at
                  tickets.mch2022.org.
                  More information is available at MCH2022.org.</p>

                <h2>Facts</h2>
                <ul>
                  <li>Dates: 22nd of July to 26th of July 2022.</li>
                  <li>Where: Scoutinglandgoed Zeewolde, Nulderpad 5, 3896LV, Zeewolde, The Netherlands.</li>
                  <li>Days: 5</li>
                  <li>Nights: 4</li>
                  <li>Visitors: 4200</li>
                  <li>Nationalities: ~50 (we don't ask)</li>
                  <li>Ticket price: 333,33<small>3333333</small> euro</li>
                  <li>Talks: 300</li>
                  <li>Self organized sessions: 100</li>
                  <li>Internet connection to Amsterdam: 100 gigabit</li>
                  <li>
                    <a href="https://de.wikipedia.org/wiki/Datenklo">Datenklo's</a>: 50
                  </li>
                  <li>Buildup: 8 days</li>
                  <li>Teardown: 3 days</li>
                  <li>Legacy: 7 camps before this, every four years since 1993</li>
                  <li>Organization time MCH: 3 years</li>
                  <li>Organization size: +/- 100 people</li>
                  <li>Volunteers at camp: hundreds; every visitor can volunteer.</li>
                  <li>Cables: 2.5 kilometer UTP, 3 kilometer Fiber.</li>
                </ul>

              </b-tab>

              <b-tab title="Press photos">
                <h2>Press Photos</h2>
                <p>Photos of our previous event. These photos can be used under the associated creative commons license
                  for promiting MCH2022. Be sure to give the appropriate attribution as stated in the license.</p>

                <b-row v-for="photo in photos" :key="photo.file" class="mt-4">
                  <b-col style="vertical-align: middle">
                    <b-img alt="Thumbnail" fluid
                           :src="'/images/gallery/thumbnails/' + photo.file"
                    />
                  </b-col>
                  <b-col style="vertical-align: middle">
                    <p>{{ photo.description }}</p>
                    <p>By: {{ photo.author }}</p>
                    <p>Licence: {{ photo.license }}</p>
                    <p>Taken at: {{ photo.taken_at }}</p>
                    <p><em><a :href="'/images/gallery/fullsize/' + photo.file_full">
                      <b-icon icon="download"/>
                      Download original file ({{ photo.file_size }})</a></em></p>
                  </b-col>
                </b-row>

              </b-tab>

              <b-tab title="Speakers">
                A subset of the speakers that can be anounced at this moment

                <ol>
                  <li v-for="talk in talks" :key="talk.title" class="mb-4">{{ talk.title }}
                    <ul>
                      <li v-html="talk.html_contents"></li>
                    </ul>
                  </li>

                </ol>


              </b-tab>

              <b-tab title="Logos & Art">
                <h2>Logo & Artwork</h2>
                Todo: add artwork.
                It's possible to generate your own artwork in the style of the event. You can do so at <a
                  href="/design/"
                  target="_blank"
                  rel="nofollow">the
                design generator, here</a>.


                <figure class="figure mt-3 mb-5 w-100" >
                  <b-img fluid thumbnail rounded src='/images/logo/MCH2022_Logo.svg' alt='svg logo' class="p-4" />
                  <figcaption class="figure-caption text-center mt-2">
                    <a href="/images/logo/MCH2022_Logo.svg" download>Vector Logo</a> |
                    <a href="/images/logo/MCH2022_Logo.png" download>Bitmap Logo</a>
                  </figcaption>
                </figure>
                <figure class="figure mt-3 mb-5 w-100" >
                  <b-img fluid thumbnail rounded src='/images/logo/MCH2022_background.svg' alt='MCH design' class="p-4" />
                  <figcaption class="figure-caption text-center mt-2">
                    <a href="/images/logo/MCH2022_background.svg" download>May Contain Hackers Wallpaper</a>
                  </figcaption>
                </figure>
                

              </b-tab>
            </b-tabs>
          </b-card>

        </b-col>
      </b-row>
    </div>

  </div>
</template>
<script>
export default {

  data() {
    return {
      photos: [
        {
          author: "Sebastian Humbek",
          description: "Camping field",
          license: "Creative Commons, Attribution, Share Alike",
          taken_at: "SHA2017",
          file_size: "2M",
          file: "SHA2017_CAMPING_FIELDS_CC_BY_SEBASTIAN_HUMBEK.jpeg",
          file_full: "SHA2017 camping fields CC BY Sebastian Humbek.jpg"
        },
        {
          author: "Sebastian Humbek",
          description: "Aerial picture of the MCH2022 terrain, taken during the previous camp: SHA2017. SHA was visited by >3500 hackers.",
          license: "Creative Commons, Attribution, Share Alike",
          taken_at: "SHA2017",
          file_size: "2M",
          file: "SHA2017_TERRAIN_CC_BY_SEBASTIAN_HUMBEK.jpeg",
          file_full: "SHA2017 terrain CC BY Sebastian Humbek.jpg",
        },
        {
          author: "Polyfloyd",
          description: "Well over 300 talks and workshops. This was the LED display on the main stage just before the opening talk.",
          license: "Creative Commons, Attribution, Share Alike",
          taken_at: "SHA2017",
          file_size: "2M",
          file: "SHA2017_STAGE_CC_BY_POLYFLOYD.jpeg",
          file_full: "SHA2017 Stage CC BY Polyfloyd.jpg",
        },
        // Todo: anything below. Filter based on the front page.
        {
          author: "Badge.team",
          description: "SHA2017_BADGE_OVERVIEW",
          license: "Creative Commons, Attribution, Share Alike",
          taken_at: "SHA2017",
          file_size: "2M",
          file: "SHA2017_BADGE_OVERVIEW_CC_BY_BADGE.TEAM.jpeg",
          file_full: "SHA2017 Badge Overview CC BY Badge.Team.jpg",
        },
        {
          author: "Badge.team",
          description: "mch2022 badge prototype",
          license: "Creative Commons, Attribution, Share Alike",
          taken_at: "MCH2022",
          file_size: "2M",
          file: "mch2022 badge CC BY BadgeTeam.jpg",
          file_full: "mch2022 badge CC BY BadgeTeam.jpg",
        },
        {
          author: "TRANSIP_(C)",
          description: "",
          license: "Creative Commons, Attribution, Share Alike",
          taken_at: "SHA2017",
          file_size: "2M",
          file: "SHA2017_BADGE_SPONSOR_TRANSIP_(C)_TRANSIP.jpeg",
          file_full: "SHA2017 Badge Sponsor TransIP (C) TransIP.jpg",
        },
        {
          author: "Polyfloyd",
          description: "SHA2017 bar just after opening",
          license: "Creative Commons, Attribution, Share Alike",
          taken_at: "SHA2017",
          file_size: "2M",
          file: "SHA2017_BAR_JUST_AFTER_OPENING_CC_BY_POLYFLOYD.jpeg",
          file_full: "SHA2017 Bar Just after opening CC BY Polyfloyd.jpg",
        },
        {
          author: "Sebastian Humbek",
          description: "Catering and track fields",
          license: "Creative Commons, Attribution, Share Alike",
          taken_at: "SHA2017",
          file_size: "2M",
          file: "SHA2017_CATERING_AND_TRACK_TENTS_CC_BY_SEBASTIAN_HUMBEK.jpeg",
          file_full: "SHA2017 catering and track tents CC BY Sebastian Humbek.jpeg",
        },
        {
          author: "Polyfloyd",
          description: "Crocodiles",
          license: "Creative Commons, Attribution, Share Alike",
          taken_at: "SHA2017",
          file_size: "2M",
          file: "SHA2017_CROCODILES_CC_BY_POLYFLOYD.jpeg",
          file_full: "SHA2017 Crocodiles CC BY Polyfloyd.jpg",
        },
        {
          author: "Elger Jonker",
          description: "Datenklo rollout",
          license: "Creative Commons, Attribution, Share Alike",
          taken_at: "SHA2017",
          file_size: "2M",
          file: "SHA2017_DATENKLO_ROLLOUT_CC_BY_ELGER_JONKER.jpeg",
          file_full: "SHA2017 Datenklo rollout CC BY Elger Jonker.jpeg",
        },
        {
          author: "Polyfloyd",
          description: "SHA2017 decoration signs",
          license: "Creative Commons, Attribution, Share Alike",
          taken_at: "SHA2017",
          file_size: "2M",
          file: "SHA2017_DECORATION_SIGNS_CC_BY_POLYFLOYD.jpeg",
          file_full: "SHA2017 Decoration Signs CC BY Polyfloyd.jpg",
        },
        {
          author: "Polyfloyd",
          description: "SHA2017 Entrance gate by night",
          license: "Creative Commons, Attribution, Share Alike",
          taken_at: "SHA2017",
          file_size: "2M",
          file: "SHA2017_ENTRANCE_GATE_BY_NIGHT_CC_BY_POLYFLOYD.jpeg",
          file_full: "SHA2017 Entrance Gate by night CC BY Polyfloyd.jpg",
        },
        {
          author: "Polyfloyd",
          description: "SHA2017 entrance gate",
          license: "Creative Commons, Attribution, Share Alike",
          taken_at: "SHA2017",
          file_size: "2M",
          file: "SHA2017_ENTRANCE_GATE_CC_BY_POLYFLOYD.jpeg",
          file_full: "SHA2017 Entrance Gate CC BY Polyfloyd.jpg",
        },
        {
          author: "Sebastian Humbek",
          description: "SHA2017 flametrower",
          license: "Creative Commons, Attribution, Share Alike",
          taken_at: "SHA2017",
          file_size: "2M",
          file: "SHA2017_FLAMETHROWER_CC_BY_SEBASTIAN_HUMBEK.jpeg",
          file_full: "SHA2017 flamethrower CC BY Sebastian Humbak.jpg",
        },
        {
          author: "Manduca",
          description: "Flyover",
          license: "Creative Commons, Attribution, Share Alike",
          taken_at: "SHA2017",
          file_size: "2M",
          file: "SHA2017_FLYOVER_SHA_CAMP_CC_BY_MANDUCA.jpeg",
          file_full: "SHA2017 flyover SHA camp CC BY Manduca.jpg",
        },
        {
          author: "Polyfloyd",
          description: "Food area",
          license: "Creative Commons, Attribution, Share Alike",
          taken_at: "SHA2017",
          file_size: "2M",
          file: "SHA2017_FOOD_AREA_CC_BY_POLYFLOYD.jpeg",
          file_full: "SHA2017 food area CC BY Polyfloyd.jpg",
        },
        {
          author: "Polyfloyd",
          description: "Glowsticks",
          license: "Creative Commons, Attribution, Share Alike",
          taken_at: "SHA2017",
          file_size: "2M",
          file: "SHA2017_GLOWSTICKS_CC_BY_POLYFLOYD.jpeg",
          file_full: "SHA2017 glowsticks CC BY Polyfloyd.jpg",
        },
        {
          author: "Sebastian Humbek",
          description: "Harbour",
          license: "Creative Commons, Attribution, Share Alike",
          taken_at: "SHA2017",
          file_size: "2M",
          file: "SHA2017_HARBOUR_CC_BY_SEBASTIAN_HUMBEK.jpeg",
          file_full: "SHA2017 harbour CC BY Sebastian Humbek.jpg",
        },
        {
          author: "Dennis van Zuijlekom",
          description: "Hach all the colors!",
          license: "Creative Commons, Attribution, Share Alike",
          taken_at: "SHA2017",
          file_size: "2M",
          file: "SHA2017_HASH_ALL_THE_COLOURS!_CC_BY_DENNIS_VAN_ZUIJLEKOM.jpeg",
          file_full: "SHA2017 Hash ALL the colours! CC BY Dennis van Zuijlekom.jpg",
        },
        {
          author: "Polyfloyd",
          description: "Hek art",
          license: "Creative Commons, Attribution, Share Alike",
          taken_at: "SHA2017",
          file_size: "2M",
          file: "SHA2017_HEK_ARTWORK_CC_BY_POLYFLOYD.jpeg",
          file_full: "SHA2017 hek artwork CC BY Polyfloyd.jpg",
        },
        {
          author: "Polyfloyd",
          description: "Main road",
          license: "Creative Commons, Attribution, Share Alike",
          taken_at: "SHA2017",
          file_size: "2M",
          file: "SHA2017_MAIN_ROAD_CC_BY_POLYFLOYD.jpeg",
          file_full: "SHA2017 Main Road CC BY Polyfloyd.jpg",
        },
        {
          author: "Billie Grace Ward",
          description: "SHA2017 moon",
          license: "Creative Commons, Attribution, Share Alike",
          taken_at: "SHA2017",
          file_size: "2M",
          file: "SHA2017_MOON_OVER_CAMP_CC_BY_BILLIE_GRACE_WARD.jpeg",
          file_full: "SHA2017 Moon over camp CC BY Billie Grace Ward.jpg",
        },
        {
          author: "Sebastian Humbek",
          description: "Poflov poffertjes",
          license: "Creative Commons, Attribution, Share Alike",
          taken_at: "SHA2017",
          file_size: "2M",
          file: "SHA2017_POFFERTJES_CC_BY_SEBASTIAN_HUMBEK.jpeg",
          file_full: "SHA2017 poffertjes CC BY Sebastian Humbek.jpg",
        },
        {
          author: "Jeroen van Veen",
          description: "",
          license: "Creative Commons, Attribution, Share Alike",
          taken_at: "SHA2017",
          file_size: "2M",
          file: "SHA2017_POLICECAR_CC_BY_JEROEN_VAN_VEEN.jpeg",
          file_full: "SHA2017 policecar CC BY Jeroen van Veen.jpg",
        },
        {
          author: "Polyfloyd",
          description: "Safe Harbour",
          license: "Creative Commons, Attribution, Share Alike",
          taken_at: "SHA2017",
          file_size: "2M",
          file: "SHA2017_SAFE_HARBOUR_CC_BY_POLYFLOYD.jpeg",
          file_full: "SHA2017 Safe Harbour CC BY Polyfloyd.jpg",
        },
        {
          author: "Billie Grace Ward",
          description: "",
          license: "Creative Commons, Attribution",
          taken_at: "SHA2017",
          file_size: "2M",
          file: "SHA2017_SIGN_BY_NIGHT_-_CC_BY_BILLIE_GRACE_WARD.jpeg",
          file_full: "SHA2017 Sign by night - CC BY Billie Grace Ward.jpg",
        },
        {
          author: "Sebastian Humbek",
          description: "",
          license: "Creative Commons, Attribution, Share Alike",
          taken_at: "SHA2017",
          file_size: "2M",
          file: "SHA2017_SILENT_LOUNGE_CC_BY_BEN_VAN_DEN_BRAND.jpeg",
          file_full: "SHA2017 Silent Lounge CC BY Ben van den Brand.png",
        },
        {
          author: "Sebastian Humbek",
          description: "",
          license: "Creative Commons, Attribution, Share Alike",
          taken_at: "SHA2017",
          file_size: "2M",
          file: "SHA2017_TERRAIN_2_CC_BY_SEBASTIAN_HUMBEK.jpeg",
          file_full: "SHA2017 terrain 2 CC BY Sebastian Humbek.jpg",
        },
        {
          author: "Badge.Team",
          description: "",
          license: "Creative Commons, Attribution, Share Alike",
          taken_at: "SHA2017",
          file_size: "2M",
          file: "SHA2017_TICKET_COUNTDOWN_CC_BY_BADGE_TEAM.jpeg",
          file_full: "SHA2017 Ticket Countdown CC BY Badge Team.jpg",
        },
        {
          author: "Polyfloyd",
          description: "",
          license: "Creative Commons, Attribution, Share Alike",
          taken_at: "SHA2017",
          file_size: "2M",
          file: "SHA2017_TRACK_2_CC_BY_POLYFLOYD.jpeg",
          file_full: "SHA2017 Track 2 CC BY Polyfloyd.jpg",
        },
      ],

      talks: [
        {
          title: 'Bellingcat',
          html_contents: 'Open Source Intelligence collective Bellingcat will give a talk about OSINT and also organise a workshop in which you can get your hands dirty in that field'
        },
        {
          title: 'Internet infrastructure is as critical as the electricity network',
          html_contents: 'Mikko Hippönen will deliver a talk on how the internet is becoming an infrastructure that is as critical as the electricity network'
        },
        {
          title: 'DIY laser harps for percussion',
          html_contents: '* Klaas van Gend and Pascal Ahout will give a talk on how to build DIY laser harps for percussion.'
        },
      ]
    }
  }

}
</script>
<style scoped lang="less">
@import (reference) "src/_variables";

.logo-row {
  @media @md-and-up {
    min-height: 400px;
  }
}

.sponsors {
  a {
    img {
      max-width: 98%;

      @media @md-and-below {
        display: block;
        margin: auto;
        max-height: 55vw; // This keeps square/long images somewhat equally sized on mobile
      }
    }

    @media @sm-and-below {
      display: block;
    }
  }
}
</style>
