<style scoped>
.blog_list .lead-paragraph {
  font-size: 1.1em;
  font-weight: bold;
}
</style>
<template>
  <div class="blog_list">

    <b-container>
      <b-row>
        <b-col class="content">
          <div class="lead-paragraph" v-if="!title">
            <h3>MCH2021 & MCH2022 Weblog Archive</h3>
          </div>
          <div class="lead-paragraph" v-if="title">
            <h3>{{ title }}</h3>
          </div>

          <div class="mb-4 overflow-auto" style="max-height: 677px">
          <div v-if="!storeLoaded && !storeError">
            Loading...
          </div>
          <div v-else-if="storeError">
            {{ storeError }}
          </div>
          <div v-else-if="!posts || posts.length === 0">
            No blog posts found, please check back later!
          </div>
          <div v-else v-for="post in myposts()" :key="post.id">

            <h4><router-link
                :to="post.status === 'publish' ? `/Blog/${post.slug}` : `/Blog/preview/${post.id}`"
                v-html="(post.status !== 'publish' ? post.status.toUpperCase() + ' ' : '') + getDate(post.date_gmt) + ': ' + post.title.rendered">
            </router-link></h4>

            <b-row >

              <b-col >

                <b-img :src="'wp-content/uploads/' + post._embedded['wp:featuredmedia'][0].media_details.file" v-if="Object.keys(post._embedded).includes('wp:featuredmedia')" left thumbnail rounded width="200%"  class="mr-2"></b-img>

                <p><span v-html="post.excerpt.rendered" ></span><b-button size="sm" variant="primary"
                :to="post.status === 'publish' ? `/Blog/${post.slug}` : `/Blog/preview/${post.id}`"
                v-html="(post.status !== 'publish' ? post.status.toUpperCase() + ' ' : '') + ' Continue Reading &gt;&gt;&gt;'">
            </b-button></p>

                </b-col>

            </b-row>
          </div>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
import {mapGetters} from "vuex";

export default {
  data: function () {
    return {}
  },
  computed: {
    ...mapGetters({
      posts: 'blog/reversedPosts',
      storeLoaded: 'blog/loaded',
      storeError: 'blog/error',
    }),
  },
  props: {
    title: {type: String},
    count: {type: Number, default: 5}
  },
  methods: {
    getDate(date) {
      return date.getFullYear().toString() + '-' +
          (date.getMonth() + 1).toString().padStart(2, '0') + '-' +
          date.getDate().toString().padStart(2, '0');
    },
    myposts: function () {
      // free off by one error:
      // let num = this.count -1;
      return this.posts.slice(0, this.count)
    },
  },
}
</script>
