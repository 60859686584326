<template>
  <div>
    <div class="content">
      <b-row>
        <b-col>

          <div class="d-none d-md-block float-right" style="margin: 2em;">
            <a role="button" class="btn-lg" href="https://cloud.ifcat.org/s/wB2Qs4GT4tWAwFZ" target="_blank"
               rel="nofollow noopener">Download the MCH2022 sponsor brochure
              <b-icon icon="arrow-up-right" shift-v="8" shift-h="-2" font-scale="0.9"></b-icon>
            </a>
          </div>
          <!-- Todo: this should be a page from wordpress, not a static page. -->
          <div class="text">
            <h1>We're looking for sponsors</h1>
            <p>
              MCH2022 is a non-profit event with a non-commercial identity, currently looking for sponsors.
              For a successful event it is none the less necessary for specific projects and teams to need contributions
              in the form of sponsoring and donations.
            </p>
            <p>
              Due to the non-commercial identity, MCH2022 <a
                href="https://cloud.ifcat.org/s/W6wiNpSyAEQdm8E" target="_blank" rel="nofollow noopener">
                  has a sponsor policy that you can find here.</a> If you would like
              to sponsor or donate to MCH2022, please contact <a href="mailto:sponsors@mch2022.org">the sponsor
              team.</a>
            </p>
          </div>
          <div class="d-block d-md-none">
            <a role="button" class="btn-block btn-lg" href="https://cloud.ifcat.org/s/wB2Qs4GT4tWAwFZ" target="_blank"
               rel="nofollow noopener">Download the MCH2022 sponsor brochure
              <b-icon icon="arrow-up-right" shift-v="8" shift-h="-2" font-scale="0.9"></b-icon>
            </a>
          </div>
        </b-col>
      </b-row>
    </div>
    <div class="content sponsors" style="margin-top: 2em;">
      <div class="text">
        <h1>Sponsors</h1>
        <b-container>
          <b-row cols="1" class="text-center">
            <b-col><h2>Platinum</h2></b-col>
          </b-row>
          <b-row cols="1" cols-lg="2" align-v="center" class="logo-row">
            <b-col>
              <company-logo caption="Computest - always on." alt="Computest Logo" filename="logo_computest.png"
                            url="https://computest.nl"/>
            </b-col>
            <b-col>
              <company-logo caption="Adyen" alt="Adyen Logo" filename="logo_adyen.svg" url="https://www.adyen.com"/>
            </b-col>
            <b-col>
              <company-logo caption="Deloitte" alt="Deloitte Logo" filename="logo_deloitte.png" url="https://www2.deloitte.com/nl/nl.html"/>
            </b-col>
          </b-row>
          <b-row cols="1" class="text-center">
            <b-col><h2>Gold</h2></b-col>
          </b-row>
          <b-row cols="1" cols-lg="2" align-v="center" class="logo-row">
            <b-col>
              <company-logo caption="Mullvad VPN - Privacy is a universal right" alt="Mullvad Logo"
                            filename="logo_mullvad.png" url="https://mullvad.net"/>
            </b-col>
            <b-col>
              <company-logo caption="Secura - A bureau veritas company" alt="Secura Logo" filename="logo_secura.svg"
                            url="https://www.secura.com"/>
            </b-col>
            <b-col>
              <company-logo caption="Deutsche Bahn Netze" alt="Deutsche Bahn Logo" filename="logo_DB.svg"
                            url="https://www.deutschebahn.com/"/>
            </b-col>
            <b-col>
              <company-logo caption="Schuberg Philis" alt="Schuberg Philis Logo" filename="logo_schubergphilis.svg"
                            url="https://www.schubergphilis.com/"/>
            </b-col>
            <b-col>
              <company-logo caption="True" alt="True Logo" filename="logo_true.svg"
                            url="https://www.true.nl/"/>
            </b-col>
          </b-row>
          <b-row cols="1" class="text-center">
            <b-col><h2>Silver</h2></b-col>
          </b-row>
          <b-row cols="1" cols-lg="2" align-v="center" class="logo-row">
            <b-col>
              <company-logo caption="Access42 - Cybersecurity" alt="Access42 Logo"
                            filename="logo_access42.png" url="https://www.access42.nl"/>
            </b-col>
            <b-col>
              <company-logo caption="Stegen Electronics" alt="Stegen Electronics Logo"
                            filename="logo_stegen.png" url="https://www.stegen.com/"/>
            </b-col>
            <b-col>
              <company-logo caption="AT Computing - Atypical Open Source Gurus" alt="AT Computing Logo"
                            filename="logo_at_computing.svg" url="https://www.atcomputing.nl/"/>
            </b-col>
            <b-col>
              <company-logo caption="Zerocopter" alt="Zerocopter Logo"
                            filename="logo_zerocopter.png" url="https://www.zerocopter.com/"/>
            </b-col>
            <b-col>
              <company-logo caption="The S-Unit Offensive Security" alt="S-unit Logo"
                            filename="logo_s-unit.svg" url="https://the-s-unit.nl/"/>
            </b-col>
            <b-col>
              <company-logo caption="TransIP" alt="TransIP Logo"
                            filename="logo_transip.svg" url="https://www.transip.nl/"/>
            </b-col>
            <b-col>
              <company-logo caption="S-RM" alt="S-RM Logo"
                            filename="logo_s-rm.svg" url="https://www.s-rminform.com/"/>
            </b-col>
            <b-col>
              <company-logo caption="Protagio" alt="Protagio Logo"
                            filename="logo_protagio.svg" url="https://www.protagio.nl/"/>
            </b-col>
            <b-col>
              <company-logo caption="Keep Ukraine Connected" alt="Keep Ukraine Connected Logo"
                            filename="logo_a2bkuc.png" url="https://nogalliance.org/our-task-forces/keep-ukraine-connected/"/>
            </b-col>
            <b-col>
              <company-logo caption="Qualcomm" alt="Qualcomm Logo"
                            filename="logo_qualcomm.svg" url="https://www.qualcomm.com/"/>
            </b-col>
          </b-row>
          <b-row cols="1" class="text-center">
            <b-col><h2>Community Sponsors</h2></b-col>
          </b-row>
          <b-row cols="2" cols-lg="3" align-v="center" class="material-sponsor">
            <b-col>
              <company-logo caption="RIPE" alt="RIPE Logo"
                            filename="logo_ripe.svg" url="https://www.ripe.net/" />
            </b-col>
            <b-col>
              <company-logo caption="John Kr" alt="John Kr Logo"
                            filename="logo_johnkr.jpg" url="https://johnkr.com/" />
            </b-col>
            <b-col>
              <company-logo caption="NLNet foundation" alt="NLNet foundation Logo"
                            filename="logo_nlnet.svg" url="https://nlnet.nl/" />
            </b-col>
          </b-row>
          <b-row cols="1" class="text-center">
            <b-col><h2>Material Sponsors</h2></b-col>
          </b-row>
          <b-row cols="2" cols-lg="3" align-v="center" class="material-sponsor">
            <b-col>
              <company-logo caption="Bit" alt="Bit Logo"
                            filename="logo_bit.png" url="https://www.bit.nl"/>
            </b-col>
            <b-col>
              <company-logo caption="Espressif" alt="Espressif Logo"
                            filename="logo_espressif.svg" url="https://www.espressif.com/"/>
            </b-col>
            <b-col>
              <company-logo caption="Lattice" alt="Lattice Logo"
                            filename="logo_lattice.svg" url="https://www.latticesemi.com/"/>
            </b-col>
            <b-col>
              <company-logo caption="Raspberry Pi" alt="Raspberry Pi Logo"
                            filename="logo_raspberry.svg" url="https://www.raspberrypi.org/"/>
            </b-col>
            <b-col>
              <company-logo caption="Speakup" alt="Speakup Logo"
                            filename="logo_speakup.svg" url="https://speakup.nl/" />
            </b-col>
            <b-col>
              <company-logo caption="Juniper Networks" alt="Juniper Logo"
                            filename="logo_juniper.svg" url="https://www.juniper.net/" />
            </b-col>
            <b-col>
              <company-logo caption="SURF" alt="SURF Logo"
                            filename="logo_surf.svg" url="https://www.surf.nl/" />
            </b-col>
            <b-col>
              <company-logo caption="i3D.net" alt="i3D.net Logo"
                            filename="logo_i3d.svg" url="https://i3d.net/" />
            </b-col>
            <b-col>
              <company-logo caption="Beetlefiber Optics" alt="Beetle Logo"
                            filename="logo_beetle.svg" url="https://www.beetlefiberoptics.com/" />
            </b-col>
            <b-col>
              <company-logo caption="Flexoptix" alt="Flexoptix Logo"
                            filename="logo_flexoptix.png" url="https://www.flexoptix.net/" />
            </b-col>
            <b-col>
              <company-logo caption="NTT Global IP Network" alt="NTT Global IP Network Logo"
                            filename="logo_ntt.svg" url="https://www.gin.ntt.net/" />
            </b-col>
            <b-col>
              <company-logo caption="P&G Rental" alt="P&G Rental Logo"
                            filename="logo_pg_rental.svg" url="http://www.pgrental.nl/" />
            </b-col>
          </b-row>
          <b-row cols="1" class="text-center">
            <b-col><h2>General donations &amp; subsidies</h2></b-col>
          </b-row>
          <b-row cols="2" cols-lg="3" align-v="center" class="material-sponsor">
            <b-col>
              <company-logo caption="Creative Industries Fund NL" alt="Creative Industries Fund NL Logo"
                            filename="logo_creative_industries_fund_NL.svg" url="https://www.stimuleringsfonds.nl/"/>
            </b-col>
          </b-row>
        </b-container>
      </div>

    </div>
    <div class="content" style="margin-top: 2em;">
      <div class="text">
        <h2>Special thanks</h2>
        <p>
          We'd like to extend special thanks to the contributors that prefer to stay anonymous and the following people
          and organisations who are instrumental in making MCH2022 happen: EventInfra, The Chaos Computer Club, 
          all orga volunteers, and everybody who will take on one or more angel shifts.
        </p>
      </div>
    </div>
  </div>
</template>
<script>

import CompanyLogo from "../CompanyLogo";

export default {
  components: {CompanyLogo}
}
</script>
<style scoped lang="less">
@import (reference) "src/_variables";

.logo-row {
  @media @md-and-up {
    min-height: 400px;
  }
}

.material-sponsors {
  @media @md-and-up {
    min-height: 200px;
  }
}

.sponsors {
  a {
    img {
      max-width: 98%;
      display: block;
      margin: auto;

      @media @md-and-below {
        max-height: 55vw; // This keeps square/long images somewhat equally sized on mobile
      }
    }

    @media @sm-and-below {
      display: block;
    }
  }
}
</style>
