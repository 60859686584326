import { UserManager, WebStorageStateStore } from 'oidc-client';

export const OAUTH_CALLBACK = '/oauth-callback';
export const OAUTH_CALLBACK_SILENT = '/oauth-callback-silent';

let authority,
    url,
    client_id
;

if (process.env.NODE_ENV === 'development') {
    authority = 'https://sso-dev.mch2022.org/auth/realms/mch2022';
    url = window.location.origin + '/#';
    client_id = 'wordpress test';
} else {
    authority = 'https://sso.mch2022.org/auth/realms/mch2022';
    url = "https://mch2022.org/#";
    client_id = 'Wordpress';
}

let settings = {
    authority,
    client_id,
    redirect_uri: url + OAUTH_CALLBACK,
    response_type: 'code',
    scope: 'openid profile',
    silent_redirect_uri: url + OAUTH_CALLBACK_SILENT,
    automaticSilentRenew: true,
    loadUserInfo: false,
    revokeAccessTokenOnSignout : true,

    stateStore: new WebStorageStateStore({ store: window.localStorage }),
    userStore: new WebStorageStateStore({ store: window.localStorage }),
};

let idsrvAuth = new UserManager(settings);
export default idsrvAuth;
