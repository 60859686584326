// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import Vuex from 'vuex'
import App from './App'
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
import VueImg from 'v-img';
import Router from 'vue-router'
import { default as mainOidc, OAUTH_CALLBACK, OAUTH_CALLBACK_SILENT } from './oidc'

import './assets/css/styles.scss';


Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)
Vue.use(Router)
Vue.use(Vuex)

const vueImgConfig = {
    // Use `alt` attribute as gallery slide title
    altAsTitle: true,
    // Display 'download' button near 'close' that opens source image in new tab
    sourceButton: true,
    // Event listener to open gallery will be applied to <img> element
    openOn: 'click',
    // Show thumbnails for all groups with more than 1 image
    thumbnails: true,
}

Vue.use(VueImg, vueImgConfig);
Vue.config.productionTip = false;

import Content_Main from "./components/pages/Home";
import Content_Example from "./components/pages/Example";
import Content_Sponsors from "./components/pages/Sponsors";
import Content_Press from "./components/pages/Press";
import Blog_Frontpage from "./components/pages/Blog_Frontpage";
import Blog_Entry from "./components/pages/Blog_Entry";
import OidcCallback from "@/components/pages/OidcCallback";
import OidcSilentCallback from "@/components/pages/OidcSilentCallback";
import Content_Memories from "./components/pages/Memories";
import Content_CoC from "./components/pages/CoC";

// navigation, see vue-router documentation.
const routes = [
    { path: '/', component: Content_Main, meta: {design: 'home'}},
    { path: '/About', component: Content_Main, meta: {design: 'home'}},
    { path: '/example', component: Content_Example, meta: {design: 'example'}},
    { path: '/Sponsors', component: Content_Sponsors, meta: {design: 'sponsors'} },
    { path: '/Press', component: Content_Press, meta: {design: 'press'} },
    { path: '/Blog', component: Blog_Frontpage, meta: {design: 'blog'}},
    { path: '/Blog/:name', component: Blog_Entry,  meta: {design: 'blog_entry'} },
    { path: '/Blog/preview/:id', component: Blog_Entry,  meta: {design: 'blog_entry'} },
    { path: '/Memories', component: Content_Memories, meta: {design: 'memories'}},
    { path: '/CoC', component: Content_CoC, meta: {design: 'CoC'}},
    { path: OAUTH_CALLBACK, component: OidcCallback, meta: {design: 'oauth'} },
    { path: OAUTH_CALLBACK_SILENT, component: OidcSilentCallback, meta: {design: 'oauth'} },
];

const router = new Router({
    routes,
});

const blogStore = {
    namespaced: true,
    state: () => ({
        posts: [],
        loaded: false,
        error: null,
    }),
    getters: {
        posts: state => state.posts,
        reversedPosts: state => state.posts.reverse(),
        loaded: state => state.loaded,
        error: state => state.error,
    },
    mutations: {
        setPosts(state, posts) {
            if (posts === null) {
                state.posts = [];
                state.loaded = false;
            } else {
                posts.sort((a, b) => {
                    return a.date.localeCompare(b.date)
                });
                posts = posts.map(post => ({...post, date_gmt: new Date(post.date_gmt + 'Z')}));
                state.posts = posts;
                state.loaded = true;
            }
        },
        setError(state, msg) {
            state.error = msg;
        }
    },
    actions: {
        async refresh(context) {
            const API_URL = '/wp-json/wp/v2/posts?per_page=100&_embed';
            context.commit('setError', null);
            context.commit('setPosts', null);

            const user = context.rootState.user;
            let status_filter = '';
            let headers = {};
            let response;

            if (user && user.id_token) {
                status_filter = '&status=publish,future,draft,pending,private'
                headers['Authorization'] = 'Bearer ' + user.id_token;

                response = await fetch(API_URL + status_filter, {
                    headers,
                });
            }

            if (!response || response.status !== 200) {
                // Failed request, most likely our bearer token is bad. Try again without.
                response = await fetch(API_URL);
            }

            response.json()
                .then(data => {
                    context.commit('setPosts', data);
                })
                .catch(() => {
                    context.commit('setError', "We're unable to retrieve our latest blog posts. Please check again later.");
                });
        }
    }
}

const store = new Vuex.Store({
    modules: {
        blog: blogStore,
    },
    state: {
        user: null,
        blog_title: null
    },
    mutations: {
        setUser(state, user) {
            state.user = user;
        },
        setBlogTitle(state, title) {
            state.blog_title = title;
        },
    },
    getters: {
        blogTitle: state => state.blog_title,
    }
});

mainOidc.events.addUserLoaded(user => {
    const previously_loaded = !!store.state.user;
    store.commit('setUser', user);

    if (!previously_loaded) {
        store.dispatch('blog/refresh').then();
    }
});

mainOidc.events.addUserUnloaded(() => {
    store.commit('setUser', null)
});

// reduce the number of function declarations
Vue.mixin({
  methods: {
    isFireFox: function(){
      return navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
    },
    isOperaMini: function() {
      return navigator.userAgent.toLowerCase().indexOf('opera mini') > -1;
    },
    doesNotSupportBackdropFilter: function(){
        return (this.isFireFox() || this.isOperaMini())
    },
  },
})

Vue.prototype.$oidc = mainOidc;
/* eslint-disable no-new */
new Vue({
    router,

    el: '#app',
    name: 'app',
    store: store,

    data: {
        config: {},
    },
    watch: {
        '$route': () => {
            document.body.scrollTop= 0;
            document.documentElement.scrollTop = 0;
        },
    },

    render: h => h(App)
}).$mount("#app");

(async () => {
    store.commit('setUser', await mainOidc.getUser());
    store.dispatch('blog/refresh').then();
})();
