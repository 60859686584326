<template type="x-template" id="bottom_menu_template">
  <div class="bottom-content-container">

    <b-row cols="1" cols-sm="2" cols-lg="4">
      <b-col>
        <div class="content blobje">
          <h4>About</h4>
          <ul>
            <li><a href="https://wiki.mch2022.org/Planning" rel="noopener">Dates and times</a></li>
            <li><a href="https://wiki.mch2022.org/Route" rel="noopener">Location & Route</a></li>
            <!-- <li><a href="https://wiki.mch2022.org/Code_of_Conduct" rel="noopener">Code of Conduct</a></li> -->
            <li><router-link to="/CoC"> Code of Conduct</router-link></li>
            <li><a href="https://design.mch2022.org/" rel="noopener" style="">Design generator</a></li>
            <li><router-link to="/Blog"> News</router-link></li>
          </ul>
        </div>
      </b-col>
      <b-col>
        <div class="content blobje">
          <h4>Visitors</h4>
          <ul>
            <li><a href="https://wiki.mch2022.org/" rel="noopener">Wiki</a></li>
            <li><a href="https://wiki.mch2022.org/Villages" rel="noopener">Villages</a></li>
            <li><a href="https://wiki.mch2022.org/Guide" rel="noopener">Camping Guide</a></li>
            <li><a href="https://tickets.mch2022.org" rel="noopener">Ticket Shop</a></li>
            <li><a href="https://maps.mch2022.org" rel="noopener"> Terrain Map</a></li>
          </ul>
        </div>
      </b-col>
      <b-col>
        <div class="content blobje">
          <h4>Volunteering</h4>
          <ul>
            <li><a href="https://wiki.mch2022.org/Volunteering_Guide" rel="noopener">How to join</a></li>
            <li><a href="https://wiki.mch2022.org/Teams" rel="noopener">Teams</a></li>
            <li><a href="https://wiki.mch2022.org/Vacancies" rel="noopener">Vacancies</a></li>
            <li><a href="https://wiki.mch2022.org/Meetings" rel="noopener">Meetings</a></li>
            <li><a href="https://angel.mch2022.org" rel="noopener">Angel system</a></li>
          </ul>
        </div>
      </b-col>
      <b-col>
        <div class="content blobje">
          <h4>Contact</h4>
          <ul>
            <li><a href="mailto:info@mch2022.org" style="white-space: nowrap;"><b-icon icon="envelope"></b-icon> info@mch2022.org</a></li>
            <li><a href="https://chaos.social/@mch2022camp" target="_blank" rel="nofollow noopener" style="white-space: nowrap;"><b-icon icon="chat"></b-icon> @mch2022camp@chaos.social</a></li>
            <li><a href="https://twitter.com/MCH2022Camp" target="_blank" rel="nofollow noopener"><b-icon icon="chat"></b-icon> @MCH2022Camp</a></li>
            <li><a href="https://irc.oftc.net/" target="_blank" rel="nofollow noopener"><b-icon icon="terminal"></b-icon> #MCH2022 on OFTC</a></li>
            <li><router-link to="/Press"><b-icon icon="Newspaper"></b-icon> Press</router-link></li>  
          </ul>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
}
</script>
<style scoped>
.bottom-content-container {
    margin-top: 2em;
    font-variant: all-small-caps;
}

.blobje {
    border: 10px solid #eeeeeeaa;
    border-radius: 10px;
    overflow: hidden;
    margin-bottom: 10px;
    background-color: var(--background-color);
    color: var(--color);
}

.blobje:hover {
    overflow: visible;
}

.bottom-content-container li {
    list-style: none;
    margin-left: -40px;
}

h4, a, a:hover, a:visited, a:link, a:active {
    color: var(--color);
}

</style>
