<template>
    <b-container>


      <template v-if="!storeLoaded && !storeError">
        <b-row>
          <b-col class="content">
            Loading...
          </b-col>
        </b-row>
      </template>
      <template v-else-if="storeError">
        <b-row>
          <b-col class="content">
            {{ storeError }}<br/>

            <b-button v-on:click="reloadPosts">
              Reload posts
            </b-button>
          </b-col>
        </b-row>

      </template>
      <template v-else>

        <b-row v-for="(post, index) in posts" :key="post.slug" class="content" style="margin-bottom: 2em;">

            <router-link :to="post.status === 'publish' ? `/Blog/${post.slug}` : `/Blog/preview/${post.id}`"><h1 v-html="post.title.rendered" class="Blog_Entry__Title"></h1></router-link>
            <!-- Why TF is this not placed on a new line? --><hr>
            <p class="w-100 text-right">{{ post.status === 'publish' ? 'Published' : post.status.toUpperCase() }} on: <b-icon icon="clock"></b-icon> <span v-html="getDate(post.date_gmt)"></span><span v-if="getAuthor(post).length">, by: {{ getAuthor(post).join(', ') }}</span></p>

            <div v-html="index === 0 ? post.content.rendered : post.excerpt.rendered" class="Blog_Entry__Content"></div>

            <div v-show="index" class="w-100">
                <b-button size="sm" variant="primary"
                      :to="post.status === 'publish' ? `/Blog/${post.slug}` : `/Blog/preview/${post.id}`"
                      v-html="(post.status !== 'publish' ? post.status.toUpperCase() + ' ' : '') + ' Continue Reading &gt;&gt;&gt;'" />
                <br/>
            </div>


            <div class="d-block">
                Permalink: &nbsp;<router-link
                    :to="'/Blog/' + post.slug"
                    v-html="getDate(post.date_gmt) + ': ' + post.title.rendered">
                </router-link>
            </div>


        </b-row>

      </template>


    </b-container>
</template>
<script>
import {mapGetters} from "vuex";

export default {
  data: function () {
    return {}
  },
  computed: {
    ...mapGetters({
      posts: 'blog/reversedPosts',
      storeLoaded: 'blog/loaded',
      storeError: 'blog/error',
    }),
  },
  props: {},
  methods: {
    getDate(date) {
      return date.getFullYear().toString() + '-' +
          (date.getMonth() + 1).toString().padStart(2, '0') + '-' +
          date.getDate().toString().padStart(2, '0');
    },
    getAuthor(post) {
      return post._embedded['wp:term'][0].map(a => a.name).filter(a => a.toLowerCase().startsWith('team:'));
    },
    reloadPosts() {
      this.$store.dispatch('blog/refresh').then();
    },
  },
}
</script>
