<template >
    <div>
        <galleryx></galleryx>
    </div>
    
    
</template>
<script>
import galleryx from "@/components/Gallery";
    export default {
        data: () => ({

        }),
        components: {galleryx},
        props: {},
        methods: {},
}
</script>
