<style scoped>
    .content_example {

    }
</style>
<template >
    <b-container class="content_example">
        <b-row>
            <b-col>
                Hello
            </b-col>
            <b-col>
                World!
            </b-col>
        </b-row>
    </b-container>
</template>
<script>
    export default {
        data: () => ({

        }),
        props: {},
        methods: {},
}
</script>
